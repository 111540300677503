import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'

/** API AuthDirectLogin */
export function getAuthLogin(key1, key2) {

  var uri = _GLOBAL.URLBACKEND + '/authdirectlogins/'+key1+'/'+key2
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
  // console.log(response)
  return response
}

/** API AuthDirectLogin ==== */

